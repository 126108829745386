<template>
  <v-container class="pa-0" fluid>
    <page-title
        :permissionAdd="this.$store.getters['permissions/checkPermission']('ware_create')"
        :heading="$t('erp.lang_garnishGroups')"
        :icon="icon"
        :subheading="$t('erp.lang_garnishGroups')"
        show-add-button
    ></page-title>
    <div class="app-main__inner">
      <GarnishGroupsComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import GarnishGroupsComponent from "@/components/erp/baseData/garnishgroup/GarnishGroupsComponent";


export default {
  name: "GarnishGroups",
  components: {GarnishGroupsComponent, PageTitle},
  data(){
    return{
      icon:'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>